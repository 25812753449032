import { render, staticRenderFns } from "./multilingual-setting.vue?vue&type=template&id=a9c1bce8&scoped=true&"
import script from "./multilingual-setting.vue?vue&type=script&lang=js&"
export * from "./multilingual-setting.vue?vue&type=script&lang=js&"
import style1 from "./multilingual-setting.vue?vue&type=style&index=1&id=a9c1bce8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9c1bce8",
  null
  
)

export default component.exports